import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/layout"

export const query = graphql`
  query {
    video: contentfulAsset(id: { eq: "bc9d5602-d3aa-5969-b29d-0f9231d86fe1" }) {
      id
      title
      file {
        fileName
        url
        contentType
      }
    }
    figures: allContentfulAsset(
      filter: { title: { regex: "/figure/" } }
      sort: { fields: title }
    ) {
      nodes {
        id
        title
        file {
          fileName
          url
          contentType
        }
      }
    }
  }
`

const Research = ({ data }) => {
  const { figures, video } = data
  const { url, contentType } = video.file
  const [figure1, figure2, figure3, figure4, figure5] = figures.nodes
  return (
    <Layout>
      <div className="container research">
        <div className="section">
          <h1 className="title is-1 mb-6">Our Current Research</h1>
          <div className="content has-text-justified">
            <p className="is-size-5">
              The Chromatin Biology Laboratory was established by Dr Vaquero in
              2008, with the major aim to understand the mechanisms of stress
              response and their impact in cancer and aging. Specifically, the
              group focus their efforts in defining the contribution of sirtuins
              to this response in the maintenance of genome stability,
              epigenetics and metabolic homeostasis. To fulfil this main
              objective, the work of the group covers a range of research from
              basic aspects of sirtuin biology to the study of their
              contribution to the development of human pathologies, such as
              cancer and aging.
            </p>
            <p className="mb-6 is-size-5">
              Since its foundation, the lab has described several novel
              Sirtuins-dependent mechanisms in genome stability protection and
              epigenetics (Mol Cell 2011, Genes &Dev 2013, Epigenetics 2017,
              Cell Rep 2017, Nat comm 2018, Sci Adv 2020, Aging 2021), has
              developed fruitful collaborations in this context (Dev. Cell 2013;
              EMBOJ 2016; J Hepatol 2017;Sci Reports 2017; Cell Death dis 2018;
              NAR 2017,2019,2020; PNAS 2017,2020) and has participated in the
              main discussions of Sirtuins field (Genes&Dev 2009, Science 2010,
              Cancer Cell 2012, Oncogene 2014, FEBS J 2015, Proteomics 2017; Mol
              Cell Oncol 2018; Mol. Reprod. Dev 2020).
            </p>
            <figure className="image is-marginless is-flex is-flex-direction-column is-align-items-center mb-6 p-4">
              <img
                className="figure mb-6"
                src={figure1.file.url}
                alt="Sirtuins are NAD+"
              />
              <figcaption>
                <strong>
                  Fig1. Sirtuins are NAD<sup>+</sup>- dependent enzymes that
                  play a key role in the response to different types of stress
                  including Metabolic, oxidative and genotoxic stress.
                </strong>{" "}
                These stress conditions are a threat in both Prokaryotes and
                Eukaryotes which have led to the development of a complex set of
                mechanisms to efficiently adapt to these conditions. In
                Eukaryotes the response to these conditions takes place at both
                genomic level and metabolic level. Sirtuins coordinate the
                response at both levels.
              </figcaption>
            </figure>
            <h4 className="title is-4">
              The main strands of the group’s work are:
            </h4>
            <h5 className="title is-5">Sirtuin enzymatic duality</h5>
            <p className="mb-6 is-size-5">
              Although the majority of sirtuins are NAD<sup>+</sup> - dependent
              deacetylases, some family members such as SIRT6, also harbor a
              second enzymatic activity, a mono-ADP-ribosyltransferase (mADPRT)
              activity. This functional duality is intriguing and is one focus
              of the group’s work. Studies from our group identified SIRT7 as a
              dual Sirtuin, and found that, contrary to what was previously
              assumed, the ADPRT activity in both SIRT6 and SIRT7 lies in a
              second active site located in a different protein domain away from
              the primary deacetylation site (Simonet el al., 2020). The active
              site is based in a ELHGN motif and is conserved through evolution
              in the whole SIRT6/SIRT7 lineage. In this context, residues E and
              N of the motif are key players in the mADPRT activity (labeled in
              blue in the video)(Fig2).
            </p>
            <figure className="image is-marginless mb-6 p-4">
              <img
                className="figure mb-6"
                src={figure2.file.url}
                alt="The mADPRT activity"
              />
              <figcaption>
                <strong>
                  Fig2. The mADPRT activity of dual Sirtuins SIRT6 and SIRT7 is
                  catalyzed by a second active site in protein domain physically
                  separated from the primary deacetylation site (in red).
                </strong>{" "}
                In the cartoon the structure of the SIR2 conserved domain of
                SIRT7 is shown. Top left, the mADPRT domain forms a cavity
                containing the conserved motif ELHGN. Top right, E and N are key
                in the mADPRT catalytic activity. SIRT7 N189 mutated to Ala
                abrogated specifically the mADPRT activity but not the
                deacetylase activity (HDAC) tested in in vitro assays with a
                H3K18 acetylated peptide. Mutation of H187Y had the opposite
                effect (From Simonet et al., Sci Adv. 2020). Bottom, video of
                the perspective around the SIRT7 catalytic domain ending in the
                mADPRT domain. Residues E and N are exposed in the cavity and
                marked in blue.
              </figcaption>
            </figure>
            <figure class="image is-marginless mb-6">
              <video className="video" controls>
                <source src={url} type={contentType} />
              </video>
            </figure>
            <p className="is-size-5">
              The work of the group aims to define the nature of this duality
              through:
            </p>
            <ul className="mb-6 is-size-5">
              <li>Defining the functional interplay between both activities</li>
              <li>
                Molecular and functional characterization of the poorly
                understood mADPRT activity
              </li>
              <li>Identification of novel targets of these activities</li>
              <li>
                The contribution of both enzymatic activities to the role of
                SIRT6/SIRT7 in cancer
              </li>
            </ul>
            <h5 className="title is-5">
              Sirtuin-dependent mechanisms of genomic stability
            </h5>
            <p className="is-size-5">
              The protection of genome integrity is a major priority for the
              cells. It involves a wide range of factors, mechanisms and
              pathways that are intimately coupled with the basic regulatory
              circuitry that controls cell life and survival. Among the most
              relevant pathways are DNA repair and DNA damage signaling, cell
              cycle and the regulation of the dynamics of global chromatin
              structure.
            </p>
            <p className="is-size-5">
              Our group aims to define the safeguard mechanism of the response
              to these stress response conditions through the following aspects:
            </p>
            <ul className="mb-6 is-size-5">
              <li>
                Maintenance of constitutive Heterochromatin (CH) structure
              </li>
              <li>DNA damage Signaling and repair</li>
              <li>Cell cycle checkpoint control</li>
              <li> Epigenetic regulation of Meiosis and Gametogenesis</li>
            </ul>
            <p className="is-size-5">
              In the first case, our studies center in Pericentric
              Heterochromatin (PCH) where we have done important discoveries to
              understand both the specific contribution of the heterochromatin
              structural proteins HP1 isoforms HP1a,b,g in PCH structure, and
              the involvement of Sirtuins in PCH integrity. In this sense our
              studies showed that HP1a and g are functionally associated to
              Suv39h1, the main H3K9me3 histone methyltransferase and a keystone
              of genome stability (Raurell-Vila et al., Epigenetics 2017).
              Interestingly, while HP1a has a specific role in the maintenance
              of PCH epigenetic and structural compartmentalization, HP1b is
              functional associated to H4K20me3 (Bosch-Presegue et al., Cell
              Reports 2017) (Fig3).
            </p>
            <figure className="image is-marginless mb-6 p-4">
              <img
                className="figure mb-6"
                src={figure3.file.url}
                alt="The mADPRT activity"
              />
              <figcaption>
                <strong>
                  Fig3. HP1 isoforms play distinctive roles in pericentric
                  heterochromatin (PCH).
                </strong>{" "}
                Top, HP1α defines, together with CTCF, a compartmentalized
                structure in PCH foci that restricts spreading epigenetic marks
                H4K20me3 and H3K27me3. Bottom, loss of HP1α impairs CTCF
                localization and promotes spreading of all three
                heterochromatin-associated factors in PCH (From Bosch-Presegué
                et al., Cell Rep. 2017).
              </figcaption>
            </figure>
            <p className="is-size-5">
              Regarding the role of Sirtuins in this context, we previously
              defined a functional interplay between Suv39h1 and SIRT1 in PCH
              regions (Vaquero et al., Nature 2007). Our studies discovered a
              new mechanism of control of PCH integrity upon stress, by the
              control of Suv39h1 stability and turnover in PCH regions
              (Bosch-Presegue et al 2010). This seems to be an important
              adaptative mechanism that ensures a proper epigenetic control of
              PCH structure (Fig4).
            </p>
            <figure className="image is-marginless mb-6 p-4">
              <img
                className="figure mb-6"
                src={figure4.file.url}
                alt="The mADPRT activity"
              />
              <figcaption>
                <strong>
                  Fig4. SIRT1 binds to the histone methyltransferase SUV39H1 and
                  regulates its stability in pericentric constitutive
                  heterochromatin (PCH).
                </strong>{" "}
                Upon stress, SIRT1 upregulation promotes the stabilization of
                SUV39H1 and a subsequent increased in turnover of SUV39H1 in
                PCH, which promotes genome stability (From Bosch-Presegué et
                al., Mol. Cell 2011).
              </figcaption>
            </figure>
            <p className="mb-6 is-size-5">
              In the case of cell cycle regulation, our studies identified a
              role for SIRT2 in the control of G2/M checkpoint through
              regulation of the H4K16ac (Vaquero et al., Genes&Dev 2006, Serrano
              et al., Genes&Dev 2013), an epigenetic mark directly linked to
              genome integrity as, in contrast to the rest of acetylation marks,
              it directly regulates the folding of the chromatin fiber. SIRT2
              not only is responsible for the removal of H4K16ac before the
              start of mitosis, but also regulates the deposition of its
              antagonist mark, H4K20me1, through a functional interplay with the
              main H4K20me1 HMT (Serrano et al., Genes&Dev 2013). This interplay
              alters the global levels of H4K20me1 and its associated marks
              H4K20me2,3, which has key consequences not only in mitosis
              progression but also in DNA replication and DNA repair (Fig5).
            </p>
            <figure className="image is-marginless mb-6 p-4">
              <img
                className="figure mb-6"
                src={figure5.file.url}
                alt="The mADPRT activity"
              />
              <figcaption>
                <strong>
                  Fig5. SIRT2 regulates the key epigenetic mark H4K20me1 in G2/M
                  transition through deacetylation of H4K16ac and a functional
                  interplay with the H4K20me1-specific histone methyltransferase
                  PR-Set7.
                </strong>{" "}
                Top, metaphase chromosomes of mouse embryonic fibroblasts
                derived from mice Wt or Sirt2-/- show hyperacetylation of H4K16
                and hypomethylation of H4K20me1 in Sirt2-/- cells. Bottom left,
                DNA FISH of centromeric or telomeric regions show a wide range
                of chromosomal abnormalities in Sirt2-/- chromosomes.
                Duplication (red) or depletion (green) of the regions are
                indicated. Bottom right, Model proposed for the interplay
                between SIRT2 and PR-Set7. In G2/M SIRT2 deacetylates H4K16ac,
                allowing H4K20me1 methylation by PR-Set7, which allows proper
                compaction of chromosomes and completion of mitosis. This has an
                impact in the following cell cycle at the level of DNA
                replication and DNA repair given the key role of H4K20me2 and
                H4K20me3 in these mechanisms (From Serrano et al., Genes Dev
                2013).
              </figcaption>
            </figure>
            <h5 className="title is-5">
              Role of Sirtuins in B-cell differentiation and Leukemia
            </h5>
            <p className="is-size-5">
              Sirtuins play an important role in the hematopoietic system,
              although is poorly understood. Thus, Sirtuins are involved in the
              maintenance of hematopoietic stem cells, cell differentiation and
              immune response, and have been associated with the development of
              some types of leukemia.
            </p>
            <p className="is-size-5">
              Our current work aims to understand Sirtuin contribution to
              hematopoiesis and leukemia through the development of two main
              subjects:
            </p>
            <ul className="is-size-5">
              <li>The role of Sirtuins in B-cell differentiation</li>
              <li>Their implication in Leukemia</li>
            </ul>
            <p className="is-size-5">
              Our evidence suggest that some Sirtuins have a central role in
              specific phases of B-cell differentiation, probably though
              modulation of stress-dependent mechanisms. By a multidisciplinary
              approach from in vitro molecular studies to in vivo mouse models
              we aim to define the contribution of these Sirtuins to the
              differentiation process, the impact of sirtuin-dependent stress
              response in the maintenance of genome stability in this process,
              and its impact in immune response in vivo.
            </p>
            <p className="mb-6 is-size-5">
              We are also particularly interested in understanding the
              functional implication of sirtuins in cancer, and in particular in
              the context of hematopoietic pathologies like leukemia and
              lymphomas. Our main efforts are currently focused in two types of
              Leukemia, pediatric B-ALL and in AML. The development of our lines
              of work should provide key evidence to understand the molecular
              basis of these pathologies, and to identify novel candidates that
              can be potentially used as markers of prognosis or novel
              therapeutical targets.
            </p>
            <h5 className="title is-5">Sirtuins, CR and aging</h5>
            <p className="is-size-5">
              The implication of Sirtuins in aging seems to have three major
              coordinates, the control of stress response, the maintenance of
              genome stability and the regulation of metabolism. In this sense,
              the beneficial effects of diet interventions such as a calorie
              restriction(CR) have been shown to alleviate aging phenotype and
              increase lifespan. Given their key role in metabolic homeostasis
              Mammalian Sirtuins are involved in the response to these stress
              conditions, although whether they participate in lifespan is an
              issue still under debate. In this sense, loss of several of these
              Sirtuins have a direct impact in aging. For instance, the
              knockdown of SIRT6 and SIRT7 mice induce a hyperaccelerated aging
              phenotype (Kawahara et al., Cell 2006, Vazquez et al., EMBO J
              2016) which involves a wide range of defects that range from
              development to genome stability and metabolism.
            </p>
            <p className="mb-6 is-size-5">
              Our work found that SIRT7 plays an important role in the response
              to glucose starvation and CR through a functional interplay with
              H2A variant macroH2A through a mechanism that involves at
              different levels both enzymatic activities of SIRT7 (Simonet et
              al., Sci Adv 2020). The regulatory axis SIRT7/macroH2A regulates a
              set of genes in response to glucose starvation and CR involved in
              a wide range of functions, from growth factors, second messenger
              signaling-related factors, epigenetic regulators, and
              transcription factors, among others. Importantly, reflecting the
              close link between CR and aging, we demonstrated the
              SIRT7-dependent regulation of these genes upon aging. Our current
              studies aim to understand the involvement of Sirtuin function
              (SIRT6/SIRT7) in the beneficial effects of nutrient restriction on
              aging development. We also aim to define the specific contribution
              of mAPDRT activity of SIRT7 to this response.
            </p>
            <h5 className="title is-5">
              Development of new methodologies to measure Sirtuin activity{" "}
            </h5>
            <p className="is-size-5">
              The importance of Sirtuins in Biomedical studies is reflected by
              the considerable number of publications focused in Sirtuins in a
              wide range of functional contexts, from basic science to clinical
              studies in a wide range of human pathologies such as cancer or
              aging. For this purpose, pharmaceutical companies have engaged in
              two major approaches in Sirtuin-related lines of work, including
              1) The discovery of novel drugs to modulate the activity of
              specific Sirtuin family members; and 2) The development of methods
              to monitor Sirtuin enzymatic activity (deacetylase activity) in
              samples derived from cells or tissues.
            </p>
            <p className="is-size-5">
              One of the major handicaps in the study of Sirtuins is the lack of
              a reliable detection method that can provide a full, direct and
              specific measurement of the activity. To this date, the
              commercially available kits of sirtuin activity are mostly
              designed to monitor deacetylation and are based on the use of a
              specific acetylated peptide fused to a fluorophore that can switch
              the emission wavelength upon deacetylation. In our group we are
              currently developing a new method, currently under optimization,
              to assess both Sirtuin deacetylation and/or mADPRTion.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Research
